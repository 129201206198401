import moment from "moment";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap-v5";
import { BiArrowToBottom } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastCss } from "../../../../config/toasterConfig";
import { downloadFile } from "../../../../service/winer-service";

export function format(value) {
  if (!value) return;
  return (value =
    value.slice(0, 3) + "-" + value.slice(3, 6) + "." + value.slice(6));
}

function ResultModal(props) {
  const { isShowModal, data, onCloseModal } = props;
  const dispatch = useDispatch();

  const handleDownload = (item) => {
    if (!item) {
      return toast.error("Không có tài liệu!", toastCss);
    }
    onDownload(item);
  };
  const onDownload = async (item) => {
    try {
      dispatch(downloadFile(item)).then((res) => {
        const urlFile = item.split("/").pop();
        const blob = new Blob([res.payload], { type: "application/".ext });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "download_" + urlFile;
        a.click();
        return window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={isShowModal} backdrop="static" keyboard={false} size="lg">
        <Modal.Header>
          <Modal.Title>Kết quả gia hạn</Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="boxChatMessage"
          style={{
            maxHeight: "500px",
            height: "100%",
            overflow: "auto",
            padding: "1rem 4rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {data?.status === 1 ? (
              <h4
                style={{
                  color: "red",
                }}
              >
                Từ chối gia hạn
              </h4>
            ) : data?.status === 2 ? (
              <h4
                style={{
                  color: "green",
                }}
              >
                Đã được phê duyệt
              </h4>
            ) : (
              <h4>Chờ xử lý</h4>
            )}
          </div>
          <Row style={{ padding: "1rem 0" }}>
            <Col xs={12} xl={6}>
              <strong>Họ tên:</strong> {data?.fullName}
            </Col>

            <Col
              xs={12}
              xl={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <strong>Biển số:</strong> {format(data?.noPlaceName)}
              </div>
            </Col>
          </Row>

          <Row style={{ padding: "1rem 0" }}>
            <Col xs={12} xl={6}>
              <strong>Ngày trúng đấu giá:</strong>{" "}
              {data?.auctionDate
                ? moment(data?.auctionDate).format("DD/MM/YYYY")
                : "-"}
            </Col>

            <Col
              xs={12}
              xl={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <strong>Ngày hết hạn đăng ký:</strong>{" "}
                {data?.endDateRegisterTime
                  ? moment(data.endDateRegisterTime).format("DD/MM/YYYY")
                  : "-"}
              </div>
            </Col>
          </Row>

          {data?.status === 2 && (
            <Row style={{ padding: "1rem 0" }}>
              <Col xs={12} xl={6}>
                <strong>Phê duyệt gia hạn thêm:</strong> {data?.addDayExpire} ngày
              </Col>

              <Col
                xs={12}
                xl={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <strong>Ngày hết hạn gia hạn:</strong>{" "}
                  {data?.endDateRegisterTime
                    ? moment(data.endDateRegisterTime).format("DD/MM/YYYY")
                    : "-"}
                </div>
              </Col>
            </Row>
          )}

          {data?.status === 1 && (
            <div style={{ padding: "1rem 0" }}>
              <strong>Lý do từ chối:</strong> {data?.comment}
            </div>
          )}
          {data?.status === 2 && (
            <div style={{ padding: "1rem 0" }}>
              <span class="btn btn-sm btn-primary" onClick={() => handleDownload(data?.urlDecisionFileExtend)}>
                {" "}
                Quyết định <BiArrowToBottom />
              </span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={onCloseModal}>
            Đóng
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ResultModal;
