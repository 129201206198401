import React from "react";
import "./scss/pagnigation.scss";

const Pagination = ({postsPerPage, totalPosts,handlePageChange,handlePerRowsChange}) => {
    return (
        <>
            <ul className="d-flex flex-direction justify-content-center align-items-center">
                <li>
                    <svg width="24"
                         height="24"
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         onClick={() => handlePerRowsChange(10, 1)}
                    >
                        <path d="M14 7L10 12L14 17"
                              stroke="#0F1A2A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                        />
                    </svg>
                </li>
                <li>
                    {Array(Math.ceil(totalPosts/ postsPerPage.COMMON)).fill(0).map((item, index) => ( <button key={index} style={{color:"white",backgroundColor:"#2E3092",border:"none",marginRight:"6px"}}  onClick={() => handlePageChange(index)}>{`${index + 1}`}</button>))}
                </li>
                <li>
                    <svg width="6"
                         height="12"
                         viewBox="0 0 6 12"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         onClick={handlePerRowsChange.onClick}
                    >
                        <path d="M1 1L5 6L1 11"
                              stroke="#0F1A2A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                        />
                    </svg>
                </li>
            </ul>
        </>
    );
};

export default Pagination;
