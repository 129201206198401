import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config/customAxios";
import { toast } from "react-toastify";
import { toastCss } from "../config/toasterConfig";

export const registerAPI = createAsyncThunk(
  "register-extend/register",
  async (data) => {
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/api/app/auction-c08/upload-extend-no-place`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Gửi đơn đề nghị gia hạn thành công", toastCss);
    } catch (error) {
      if (error.status === "204")
        return toast.success("Gửi đơn đề nghị gia hạn thành công", toastCss);
      if (error.response.data.error.code === "NoPLC:ERGP02.037")
        return toast.error("Quá ngày gia hạn", toastCss);
      if (error.response.data.error.code === "NoPLC:ERGP02.036")
        return toast.error("Đang có đơn xử lý", toastCss);
      // if (error.response.data.error.code === "NoPLC:ERGP02.027")
      //   return toast.error("Lỗi xử lý", toastCss);
      toast.error("Lỗi xử lý", toastCss);
    }
  }
);
