import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, role }) => {
    const isAuthen = Boolean(localStorage.getItem('isAuthen'))


    if (!isAuthen) {
        return <Navigate to="/login" replace />;
    }
    return children;
}

export default ProtectedRoute;