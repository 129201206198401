import React from "react";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { registerAPI } from "../../service/register-extend";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { toast } from "react-toastify";
import { toastCss } from "../../config/toasterConfig";
import { fetchUserProfile } from "../../service/user-service";

import { saveAs } from "file-saver";
import { Packer } from "docx";
import { DocumentCreator } from "./cv-generator";
import { showListWiner } from "../../service/winer-service";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";

function RegisterExtend() {
  const [pageCurrent, setPageCurrent] = useState("");
  const location = useLocation();
  useEffect(() => {
    let lastPart = location.pathname.split("/").pop();
    setPageCurrent(lastPart);
  }, [location.pathname]);

  // Initially, no file is selected
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileVal, setFileVal] = useState();
  const [selectedIdentityFile, setSelectedIdentityFile] = useState(null);

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const changeIdentityFile = (event) => {
    setSelectedIdentityFile(event.target.files[0]);
  };
  const handleSubmit = async (noPlate) => {
    if (!noPlate) return;
    const dataBody = convertDataSubmit(noPlate);
    const ext = dataBody?.File?.name?.split(".")?.pop();
    const fileIdentity = dataBody?.FileIdentity?.name?.split(".")?.pop();

    if (!dataBody.File)
      return toast.error("Đơn đề nghị gia hạn không được để trống", toastCss);

    if (!dataBody.FileIdentity)
      return toast.error("Tài liệu minh chứng không được để trống", toastCss);

    if (
      ext !== "doc" &&
      ext !== "docx" &&
      ext !== "pdf" &&
      ext !== "png" &&
      ext !== "jpg" &&
      ext !== "jpeg"
    ) {
      return toast.error("Đơn đề nghị sai định dạng!", toastCss);
    }

    if (
      fileIdentity !== "doc" &&
      fileIdentity !== "docx" &&
      fileIdentity !== "pdf" &&
      fileIdentity !== "png" &&
      fileIdentity !== "jpg" &&
      fileIdentity !== "jpeg"
    ) {
      return toast.error("Tài liệu minh chứng sai định dạng!", toastCss);
    }

    dispatch(registerAPI(dataBody));
  };

  const convertDataSubmit = () => {
    const today = new Date();

    return {
      NoPlace: noPlate.licensePlate,
      MonthToExtend: 6,
      File: selectedFile,
      FileIdentity: selectedIdentityFile,
      fullName: noPlate.fullName,
      phoneNumber: noPlate.phoneNumber,
      ExtendDatetime:
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
    };
  };
  const [noPlate, setnoPlate] = useState([]);
  // bien so
  useEffect(() => {
    dispatch(showListWiner())
      .then((res) => {
        setnoPlate(res.payload.items[0]);
      })
      .catch((err) => {});
  }, []);

  // profile
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);
  const profile = useSelector((state) => {
    return state.user.currentUser;
  });
  const fullName = profile?.name || "";
  const phoneNumber = profile?.phoneNumber || "";

  // export doc
  const generate = () => {
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create([profile, noPlate]);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "Don_xin_gia_han_bien_so " + noPlate.licensePlate + ".docx");
    });
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-3">
          <div className="profile-left">
            <Sidebar pageCurrent={pageCurrent} />
          </div>
        </div>
        <div className="col-md-9">
          <div className="profile-right border rounded p-2 bg-light">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <h1 className="heading-28-bold neutral-100 text-center">
                    Đơn đề nghị gia hạn
                  </h1>
                </div>
                <hr className="mb-3" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* Table */}
                <Formik
                  initialValues={{ NoPlace: noPlate.licensePlate, File: "" }}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, noPlate);
                    resetForm();
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="mt-lg-4">
                        <div className="mt-3">
                          <span className="fw-bold">Họ và tên:</span> {fullName}
                        </div>
                        <div className="mt-3">
                          <span className="fw-bold">Số điện thoại:</span>{" "}
                          {phoneNumber}
                        </div>
                        <div className="mt-3">
                          <span className="fw-bold">
                            Chọn biển số gia hạn:{" "}
                          </span>{" "}
                          {noPlate.licensePlate}
                        </div>
                        <div className="mt-3">
                          <label className="fw-bold">
                            Đơn đề nghị gia hạn:{" "}
                          </label>
                          <a
                            className="btn btn-sm btn-primary ms-2"
                            onClick={generate}
                          >
                            Tải mẫu đơn
                          </a>
                          <div>
                            Bạn điền thông tin đầy đủ, ký tên vào đơn xin đề
                            nghị gia hạn. Chỉ được đính kèm file có định dạng
                            pdf, doc, docx, jpg, png, jpeg. Dung lượng tối đa
                            20Mb
                          </div>
                          <div>
                            <Field
                              name="File"
                              type={"file"}
                              className="input mt-2"
                              onChange={changeHandler}
                              value={fileVal}
                            />
                            {errors.File || touched.File ? (
                              <div>{errors.File}</div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-3">
                          <strong>
                            Tài liệu chứng minh lý do bất khả kháng
                          </strong>
                          <div>
                            Bạn scan tất cả tài liệu chứng minh lý do bất khả
                            kháng vào 1 file Chỉ được đính kèm file có định dạng
                            pdf, jpg, png, jpeg. Dung lượng tối đa 20Mb
                          </div>
                          <div>
                            <Field
                              name="fileIdentity"
                              type={"file"}
                              className="input mt-2"
                              onChange={changeIdentityFile}
                            />
                            {errors.fileIdentity || touched.fileIdentity ? (
                              <div>{errors.fileIdentity}</div>
                            ) : null}
                          </div>
                        </div>

                        {errors.expect || touched.expect ? (
                          <div className="notify-warning">{errors.expect}</div>
                        ) : null}
                        <div className="row mt-4 mb-5">
                          <div className="col-md-4">
                            <Button className="submit-btn" type={"submit"}>
                              Gửi đi
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterExtend;
