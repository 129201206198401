
import {useEffect, useState} from "react";
import "../../custom.scss";
import "./scss/header.scss";
import { Link, useLocation } from "react-router-dom";
import {showListCat} from "../../service/cat-service";
import {useDispatch} from "react-redux";
import { useParams } from "react-router-dom";

const MenuLogin = () => {
    const location = useLocation();

    const [pageCurrent, setPageCurrent] = useState('');
    const [catId, setCatId] = useState('');
    const params = useParams();
 
    const dispatch = useDispatch()
    const [catList, setCatList] = useState([])
    useEffect(() => {
        dispatch(showListCat()).then((res) =>  {
            setCatList(res?.payload)
        }).catch((err) => {
           console.log(err)
       });
        setCatId(params.id);
    }, [catId] );

    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);
  
    return (
    <>
        <div className="menu-main">
            <div className="container">
                <div className="nav-scroller py-1 mb-2">
                    <nav className="nav d-flex">
                
                        <Link to={"/"}  className={`pt-1 pe-4 link-secondary ${pageCurrent === ''? 'active':''}`} >
                            <span className="body-14-medium neutral-80">
                                Trang chủ
                            </span>
                        </Link>
                        {
                           catList?.map((item,index) => {
                                return <Link key={index} to={"/danh-muc/"+item.id}  className={`pt-1 pe-4 link-secondary ${pageCurrent === item.id? 'active':''}`}>
                                <span className="body-14-medium neutral-80">
                                    {item.name}
                                </span>
                            </Link>
                            })
                        }
                        <Link to={"/api/app/winer"}   className={`pt-1 pe-4 link-secondary ${pageCurrent == 'winer'? 'active':''}`}>
                            <span className="body-14-medium neutral-80">
                            Lịch sử đấu giá
                            </span>
                        </Link>
                        <Link to={"/api/app/q-a"} className={`pt-1 pe-4 link-secondary ${pageCurrent === 'q-a'? 'active':''}`}>
                            <span className="body-14-medium neutral-80">
                            Hỗ trợ khách hàng
                            </span>
                        </Link>
                      
                        <Link to={"/api/app/register-extend"} className={`pt-1 pe-4 link-secondary ${pageCurrent === 'register-extend'? 'active':''}`}>
                            <span className="body-14-medium neutral-80">
                                Đăng ký gia hạn
                            </span>
                        </Link>
                        <Link to={"/doc"} className={`pt-1 pe-4 link-secondary ${pageCurrent === 'doc'? 'active':''}`}>
                            <span className="body-14-medium neutral-80">
                                Giấy tờ pháp lý
                            </span>
                        </Link>
                        <Link to={"/api/app/profile"} className={`pt-1 pe-4 link-secondary ${pageCurrent === 'profile'? 'active':''}`}>
                            <span className="body-14-medium neutral-80">
                                Cá nhân
                            </span>
                        </Link>
                       
                    </nav>
                </div>
            </div>
        </div>


      
    </>
  );
};

export default MenuLogin;