import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import axiosInstance from "../../../../config/customAxios";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY } from "../../../../config/token";

function MessageModal(props) {
  const { isShowModal, data, onCloseModal } = props;
  const token = Cookies.get(ACCESS_TOKEN_KEY);
  const [mess, setMess] = useState([]);

  const getMessages = async (id) => {
    if (id) {
      try {
        let res = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/api/app/q-aApp-extend/async-by-user?extendIds=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMess(res.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getMessages(data?.id);
  }, [data]);

  useEffect(() => {
    autoScroll();
  }, [mess, props]);

  const autoScroll = () => {
    const messageList = document.getElementById("boxChatMessage");
    if (messageList) {
      const scrollHeight = messageList.scrollHeight;
      const height = messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  return (
    <div>
      <Modal show={isShowModal} backdrop="static" keyboard={false} size="lg">
        <Modal.Header>
          <Modal.Title>Phản hồi gia hạn</Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="boxChatMessage"
          style={{ maxHeight: "500px", height: "100%", overflow: "auto" }}
        >
          {mess.length > 0 &&
            mess?.map((item,index) =>
              item.isAdmin === true ? (
                <div
                  style={{
                    background: "#0d6efd",
                    padding: "10px 30px",
                    borderRadius: "10px",
                    color: "white",
                    width: "80%",
                    marginBottom: "10px",
                    wordBreak: "break-word",
                  }}
                  key={index}
                >
                  {item.description}
                </div>
              ) : (
                <></>
              )
            )}

          {mess.length > 0 ? (
            <p style={{ color: "red" }}>Vui lòng không trả lời lại thư này!</p>
          ) : (
            <p style={{ display: "flex", justifyContent: "center" }}>
              Không có dữ liệu
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={onCloseModal}>
            Đóng
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MessageModal;
