import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {showListWiner, downloadQD, download, downloadHD} from "../../service/winer-service";
import {docAPI, docDownloadAPI2, downloadHDList} from "../../service/doc";
import {toast} from "react-toastify";
import {toastCss} from "../../config/toasterConfig";  

const Doc = () => {

    const PAGESIZE = {
        COMMON: 10,
    }

    let initFilter = {
        MaxResultCount: PAGESIZE.COMMON,
        SkipCount: 0,
        serialNumber: '',
        fromDate: '',
        toDate: '',
    };
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState(initFilter);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showListWiner()).then((res) =>  {
            let {totalCount} = res.payload;
            setTotalCount(totalCount)
        }).catch((err) => {
            console.log(err)});
    }, []);

    const search = window.location.search;
    const params = new URLSearchParams(search);

    let docs = useSelector(state => { 
        return state.winer.items;
    })
    const [docList, setDocList] = useState([]);
    useEffect(() => {
        dispatch(docAPI()).then((res) => { 
      
            setDocList(res.payload.items);

         });
    },[]);

    const [HDList, setHDList] = useState([]);
    useEffect(() => {
        dispatch(downloadHDList()).then((res) => { 
      
            setHDList(res.payload);

         });
    },[]);


    useEffect(() => {
        if (params.get('serialNumber')) {
            let newFilter = {...filter};
            newFilter.serialNumber = params.get('serialNumber') || '';
            setFilter(newFilter);
            dispatch(showListWiner(newFilter));
        }
    }, [params.get('serialNumber')]);


    // down load quyet dinh 
    const handleDownloadQD = (item) => {
        onDownloadQD(item)
  
    }

    const onDownloadQD = async (item) => {
        try {
             dispatch(downloadQD(item.id)).then((res) => { 
                const urlFile = item.fileWinAuction
                
                if (urlFile) {
                  const blob = new Blob([res.payload], {type: 'application/octet-stream'})
                  const url = window.URL.createObjectURL(blob)
        
                  const a = document.createElement('a')
                  a.style.display = 'none'
                  a.href = url
        
                  a.download = 'QD_' + item.serialNumber + '_' + item.id + '.pdf'
                  a.click()
                  return window.URL.revokeObjectURL(url)
                }else{
                    toast.error('Bạn chưa có quyết định', toastCss)
                    return;
                }
               
            });
       } catch (error) {
          console.log(error)
       
       }
   }

//    down load van ban 
    const handleDownload  = (item) => {
        onDownload(item)

    }
    const onDownload = async (item) => {
            try {
                dispatch(download(item.id)).then((res) => {
                    
                        const urlFile = item.urlAuctionDocument;
                    if(urlFile){
                        const ext = urlFile.split(".").pop()

                        const blob = new Blob([res], {'type': 'application/'+ext})
                        const url = window.URL.createObjectURL(blob)

                        const a = document.createElement('a')
                        a.style.display = 'none'
                        a.href = url

                        a.download = 'VB'+urlFile;
                        a.click()
                        return window.URL.revokeObjectURL(url) 
                    }else{
                        toast.error('Bạn chưa có hóa đơn', toastCss)
                        return;
                    }

                });
        } catch (error) {
            console.log(error)
        
        }
        
    }

    // down load hoa don  
    const handleDownloadHD  = (item) => {
         onDownloadHD(item)

    }
    const onDownloadHD = async (item) => {
            try {
                if(!item.fkey){
                    return;
                }
                dispatch(downloadHD(item.fkey)).then((res) => {
                     
                    const dataBase64 = res.payload.data;
                    if(dataBase64){
                        const url = "data:image/png;base64,"+dataBase64
                        
                        // const blob = new Blob([urlFile], {'type': 'application/pdf'})
                        // const url = window.URL.createObjectURL(blob)

                        const a = document.createElement('a')
                        a.style.display = 'none'
                        a.href = url

                        a.download = 'HD_.pdf';
                        a.click()
                        return window.URL.revokeObjectURL(url) 
                    }else{
                        toast.error('Bạn chưa có hóa đơn', toastCss)
                        return;
                    }
                });
        } catch (error) {
            console.log(error)
        
        }
    }

    // download toan bo tai lieu 
    const handledocDownload  = (item) => {
        
        onDocDownload(item)

    }
    const onDocDownload = async (item) => {
            try {
                 dispatch(docDownloadAPI2(item)).then((res) => {
                    console.log(res.payload);

                    const dataBase64 = res.payload;
                    // const url = "data:image/png;base64,"+dataBase64
                    
                    const blob = new Blob([res.payload], {'type': 'application'})
                    const url = window.URL.createObjectURL(blob)

                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url

                    a.download = 'TB_'+item.fileAttackURL;
                    a.click()
                    return window.URL.revokeObjectURL(url) 
                });
        } catch (error) {
            console.log(error)
        
        }
    }

     

    return (
        <>
         
            <div>
                <div className="container">
                    {/* Page title */}
                    <div>
                        <h1 className="heading-28-bold neutral-100 text-center mt-5">Giấy tờ pháp lý</h1>
                    </div>
                    <div>
                       
 
                        <div className="text-center mt-5">
 
                            <div className="mt-4">
                                <div className="row mt-5 justify-content-center">
                                    {docs?.map((item,index) => (
                                    
                                        
                                        <div key={index}>
                                            <div className="col-md-4">
                                                <div className="doc-mod text-center" style={{cursor: 'pointer'}} onClick={() => handleDownloadQD(item)}>
                                                        <div className="doc-mod-img">
                                                            <img height="100px" src="theme/qd.png" /> 
                                                        </div>
                                                        <div className="doc-mod-text fw-bold mt-2">
                                                        
                                                            {
                                                                item.fileWinAuction?('Quyết định trúng đấu giá'):('Chưa có quyết định')
                                                            }
                                                        </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                    ))}
                                
                                    {docList.map((item, rowIndex) => (
                                        <div key={rowIndex} className="col-md-4">
                                            <div className="doc-mod text-center" style={{cursor: 'pointer'}} onClick={() => handledocDownload(item)}>
                                                <div className="">
                                                    <img height="100px" src="theme/tb.png" /> 
                                                </div>
                                                <div className="fw-bold text-center mt-2">{item.additionType===2?'Quyết định điều chỉnh': item.description}</div>
                                            </div>
                                        </div>
                                    ))}

                                    {HDList.map((item, rowIndex) => (
                                        
                                        <div key={rowIndex} className="col-md-4">
                                       
                                            <div className="doc-mod text-center" style={{cursor: 'pointer'}} onClick={() =>  handleDownloadHD(item)}>
                                                <div className="">
                                                    <img height="100px" src="theme/hd.png" /> 
                                                </div>
                                                <div className="fw-bold text-center mt-2">Hóa đơn</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                         
                    </div>
                   
                </div>
            </div>
        </>
    );
};

export default Doc;
