import Cookies from "js-cookie";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/core/button/button-default/button";
import { clearLocalStorage } from "../../core/ultis/ultis";
import "../../custom.scss";
import "./scss/header.scss";


const Header = () => {
const navigate = useNavigate();
const selectUser = useSelector((state) => state.user.currentUser);
// const [cookieLogin, setCookieLogin] = useState('')
const cookieLogin = Cookies.get("ACCESS_TOKEN_KEY");


return (
    <>
 

      <div className="d-flex align-items-center container mg-top-header  w-full ">
        <div className="w-full row justify-content-between">
          <div className="p-b-5 col-12 d-flex align-items-center">
            <div className="col-5">
              <div className="float-left col-md-1 offset-0">
                <img
                  src="/assets/images/header/img.png"
                  alt=""
                  className="h-44 w-39"
                />
              </div>
              <div className="float-left col-6 logo-title">
                <div className="body-14-bold primary-1 mg-0-0-4-13">
                  BỘ CÔNG AN
                </div>
                <div className="body-14-bold primary-1 mg-0-0-0-13">
                  CỤC CẢNH SÁT GIAO THÔNG
                </div>
              </div>
            </div>
            <div className="col-7  justify-content-end">
                <div className="header-info   align-items-center" style={{ flexShrink: 0 }} >
                    {
                        cookieLogin  && selectUser ? (
                            <div>
                                
                                <div className="row justify-content-end">
                                    <div className="col-md-6 text-end">
                                        <div className="mt-2 info-profile">
                                           
                                        </div>
                                    </div>
                                    <div className="col-md-4  text-end">
                                        
                            
                                    </div>
                                    <div className="col-md-6 text-end">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <img src="/theme/user.png" height="40px" />
                                                        <span style={{ paddingRight: "5px" }} className="mobile-hidden-only"> {selectUser ? selectUser.email : ""} </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  href={"/api/app/profile"} className="py-1">
                                                                <span className="body-14-medium ">Cá nhân</span>
                                                        </Dropdown.Item>
                                                        
                                                        <Dropdown.Item href={"/api/app/register-list"} className="py-1">
                                                                <span className="body-14-medium ">
                                                                    Danh sách đơn gia hạn
                                                                </span>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href={"/cashback"} className="py-1">
                                                                <span className="body-14-medium">
                                                                Hoàn tiền
                                                                </span>
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item href={"/sale"} className="py-1">
                                                                <span className="body-14-medium">
                                                                    Chuyển nhượng 
                                                                </span>
                                                        </Dropdown.Item>
  */}


                                                        <Dropdown.Item onClick={() => { navigate("/"); clearLocalStorage(); Cookies.remove("ACCESS_TOKEN_KEY"); }} >
                                                            <Button  style={{ padding: "14px 24px", display: "inline-block" }} text="Đăng xuất"  />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                       
                                    </div>
                                </div>
                               
                            </div>
                               
                        ) : (
                            <div className="row justify-content-end">
                                <div className="col-md-6 text-end">
                                    <a className="a-buttom" href="/login">Đăng nhập</a>
                                </div>
                           </div>
                        )
                    }


                           
                </div>
            </div>
          </div>
        </div>
      </div>

    
    </>
  );
};
export default Header;
